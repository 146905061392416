<template>
	<h1>Manage Plans</h1>
	<div v-if="dataError" class="critical-error">An error has occurred.</div>
	<div v-else-if="plans === null" class="loading-indicator">
		<img src="@/assets/images/loading.gif">
	</div>
	<div v-else>
		<div id="plan-list-wrapper">
			<div id="filters" class="form">
				<div>
					<label for="customer-type-selection">Customer Type</label>
					<select id="customer-type-selection" v-model="displayCustomerType" @change="loadPlans(true, true)">
						<option value="all">All</option>
						<option value="corporate">Corporate</option>
						<option value="residential">Residential</option>
					</select>
				</div>
				<div>
					<label for="provider-selection">Provider</label>
					<select id="provider-selection" v-model="displayProvider" @change="loadPlans(true, true)">
						<option value="all">All</option>
						<option value="OctaneCorporate">Telco in a Box Corporate</option>
						<option value="OctaneCorporateLegacy">Telco in a Box Corporate Legacy</option>
						<option value="OctaneResidential">Telco in a Box Residential</option>
					</select>
				</div>
				<div>
					<label for="service-type-selection">Service Type</label>
					<select id="service-type-selection" v-model="displayServiceType" @change="loadPlans(true, true)">
						<option value="all">All</option>
						<option value="NBN">NBN</option>
						<option value="Mobile">Mobile</option>
						<option value="CloudPBX">Cloud PBX</option>
						<option value="InboundVoice">Inbound Voice</option>
					</select>
				</div>
				<div>
					<label for="status-selection">Status</label>
					<select id="status-selection" v-model="displayStatus" @change="loadPlans(true, true)">
						<option value="all">All</option>
						<option value="active">Active</option>
						<option value="inactive">Inactive</option>
					</select>
				</div>
				<button v-if="canCreate" type="button" @click="createPlan">Create New Plan</button>
			</div>
			<div v-show="loadingPlans" class="loading-indicator">
				<img src="@/assets/images/loading.gif">
			</div>
			<div id="plan-list" :class="{hidden: loadingPlans}">
				<div v-if="plans.length == 0" class="no-plans">
					There are no plans to display.
				</div>
				<table v-else class="data-table">
					<tr>
						<th>Plan Name</th>
						<th>Plan Group</th>
						<th>Customer Type</th>
						<th>Service Type</th>
						<th>Data Allowance</th>
						<th>Technology Types</th>
						<th>Speed</th>
						<th>Contract Months</th>
						<th>Monthly Cost</th>
						<th>Setup Cost</th>
						<th>Provider</th>
						<th>Status</th>
						<th v-if="canEdit || canDelete" :colspan="(canEdit && canDelete) ? 2 : 1">{{(canEdit && canDelete) ? 'Actions' : 'Edit'}}</th>
					</tr>
					<tr v-for="(plan, index) in plans" :class="{'inactive-plan': (plan.status == 'inactive')}">
						<td>{{plan.plan_name}}</td>
						<td>{{plan.plan_group.group_name}}</td>
						<td>{{capitaliseFirstLetter(plan.customer_type)}}</td>
						<td>{{formatServiceType(plan.service_type)}}</td>
						<td :class="{'empty-field': !plan.plan_options.hasOwnProperty('data_allowance')}">{{plan.plan_options.hasOwnProperty('data_allowance') ? ((plan.plan_options.data_allowance === null) ? 'Unlimited' : formatBytes(plan.plan_options.data_allowance)) : 'N/A'}}</td>
						<td :class="{'empty-field': !plan.plan_options.technology_types}">{{plan.plan_options.technology_types ? plan.plan_options.technology_types.join(', ') : 'N/A'}}</td>
						<td :class="{'empty-field': (!plan.plan_options.download_speed || !plan.plan_options.upload_speed)}">{{(plan.plan_options.download_speed && plan.plan_options.upload_speed) ? `${formatTransferSpeed(plan.plan_options.download_speed)} / ${formatTransferSpeed(plan.plan_options.upload_speed)}` : 'N/A'}}</td>
						<td>{{plan.contract_months}}</td>
						<td>${{plan.monthly_cost}}</td>
						<td>${{plan.setup_cost}}</td>
						<td>{{formatProvider(plan.provider)}}</td>
						<selectable-value-cell :record-index="index" :selectable-values="{'active': 'Active', 'inactive': 'Inactive'}" :selecting-record-index="changeStatusIndex" :is-selecting="changingStatus" :allow-selection="canEdit" :is-error="changeStatusErrors[index]" @show-selection="display => changeStatusIndex = display ? index : null" @save-selection="changeStatus">{{capitaliseFirstLetter(plan.status)}}</selectable-value-cell>
						<td v-if="canEdit" class="edit-cell" @click="editPlan(plan)" title="Edit Plan">&#x1F589;</td>
						<td v-if="canDelete" class="delete-cell" @click="deletePlan(plan)" title="Delete Plan">X</td>
					</tr>
				</table>
				<pagination v-model="displayPage" :data="plans" @page-changed="loadPlans"></pagination>
			</div>
		</div>
		<edit-plan-form v-if="editingPlan" :plan-id="editedPlan" @close="editingPlan = false" @completed="loadPlans(false)"></edit-plan-form>
		<delete-plan-confirmation v-if="deletingPlan" :plan="deletingPlan" @close="deletingPlan = null" @completed="loadPlans(false)"></delete-plan-confirmation>
	</div>
</template>

<script>
	import Pagination from '@/components/Pagination';
	import SelectableValueCell from '@/components/SelectionCells/SelectableValueCell';
	import DeletePlanConfirmation from '@/components/Modals/Plans/DeletePlanConfirmation';
	import EditPlanForm from '@/components/Modals/Plans/EditPlanForm';
	import {mapGetters} from 'vuex';
	
	export default {
		data() {
			return {
				plans: null,
				displayCustomerType: 'all',
				displayProvider: 'all',
				displayServiceType: 'all',
				displayStatus: 'active',
				loadingPlans: false,
				dataError: false,
				editingPlan: false,
				editedPlan: null,
				deletingPlan: null,
				changeStatusIndex: null,
				changingStatus: false,
				changeStatusErrors: {},
				displayPage: 1
			}
		},
		computed: {
			canCreate() { // Used to determine whether the authenticated user has the appropriate permission to create service plans.
				return this.hasPermission('plans', 'create');
			},
			canEdit() { // Used to determine whether the authenticated user has the appropriate permission to edit service plans.
				return this.hasPermission('plans', 'edit');
			},
			canDelete() { // Used to determine whether the authenticated user has the appropriate permission to delete service plans.
				return this.hasPermission('plans', 'delete');
			},
			...mapGetters(['hasPermission'])
		},
		components: {
			Pagination, SelectableValueCell, EditPlanForm, DeletePlanConfirmation
		},
		async created() { // When the page is loaded, get the list of service plans.
			await this.loadPlans();
		},
		methods: {
			async loadPlans(refresh = true, resetPagination = false) { // Performs the API request to get the list of service plans.
				// If the pagination needs to be reset, set the displayed page to Page 1.
				if(resetPagination) {
					this.displayPage = 1;
				}
				
				// If we're visually refreshing the page (rather than stealthily updating the service plan list), display the loading indicator.
				if(refresh) {
					this.loadingPlans = true;
				}
				
				// Generate the query string for the API request based on the filters selected.
				let queryString = '';
				const filters = {'customer-type': this.displayCustomerType, 'provider': this.displayProvider, 'service-type': this.displayServiceType, 'status': this.displayStatus};
				for(const filter in filters) {
					const filterValue = filters[filter];
					if(filterValue != 'all') { // Each query string argument is only required if the filter is actually applied.
						queryString += `&${filter}=${filterValue}`;
					}
				}
				
				// Perform the API request to get the list of service plans.
				try {
					const response = await this.HTTP.get(`plans?page=${this.displayPage}` + queryString);
					this.plans = response.data.data;
				} catch(error) { // If there was an error obtaining the service plan list, display the generic error message.
					this.dataError = true;
				} finally { // Regardless of whether the API request was successful, hide the loading indicator.
					this.loadingPlans = false;
				}
			},
			async changeStatus(newStatus) { // Performs the API request to update the status for the service plan identified by the changeStatusIndex property.
				const plan = this.plans[this.changeStatusIndex];
				if(newStatus != plan.status) { // Only continue if the selected status is different from the current status.
					// Mark the status change as pending to display the loading indicator, and remove the status change error if it is displayed.
					this.changingStatus = true;
					this.changeStatusErrors[this.changeStatusIndex] = false;
					
					try {
						// Perform the API request to update the status of the given service plan.
						await this.HTTP.post(`plans/${plan.id}/status`, {status: newStatus});
						if(plan == this.plans[this.changeStatusIndex]) { // Update the status displayed for the service plan (only if the list of service plans hasn't been refreshed since this API request was initiated).
							plan.status = newStatus;
						}
					} catch(error) { // If there was an error updating the status, handle it as appropriate depending on the error message (only if the list of service plans hasn't been refreshed since this API request was initiated).
						if(plan == this.plans[this.changeStatusIndex]) {
							if(error.response && error.response.status == 400 && error.response.data && error.response.data.error == "The new status must be different from the current status.") { // If the error is that the new status must be different from the current status, then it simply means that the status has changed since the service plan list was loaded, so just quietly update the status that is displayed.
								plan.status = newStatus;
							} else { // For any other error, display the error message below the status name.
								this.changeStatusErrors[this.changeStatusIndex] = true;
							}
						}
					} finally { // Regardless of whether the API request was successful, hide both the status dropdown and loading indicator.
						this.changingStatus = false;
						this.changeStatusIndex = null;
					}
				} else { // If the selected status is the same as the current status, simply hide the status dropdown.
					this.changeStatusIndex = null;
				}
			},
			createPlan() { // Displays the modal for creating a new service plan.
				this.editedPlan = null;
				this.editingPlan = true;
			},
			editPlan(plan) { // Displays the edit plan modal for the service plan at the given index in the service plans array.
				this.editedPlan = plan.id;
				this.editingPlan = true;
			},
			deletePlan(plan) { // Displays the deletion confirmation modal for the plan at the given index in the service plans array.
				this.deletingPlan = plan;
			},
			capitaliseFirstLetter(string) { // Returns the input string with the first letter capitalised.
				return string.charAt(0).toUpperCase() + string.slice(1);
			},
			formatServiceType(serviceType) { // Given the service type for a plan, returns the user-friendly representation.
				switch(serviceType) {
					case 'CloudPBX':
						return 'Cloud PBX';
					case 'InboundVoice':
						return 'Inbound Voice';
					default:
						return serviceType;
				}
			},
			formatProvider(provider) { // Given the provider for a plan, returns the user-friendly representation.
				switch(provider) {
					case 'OctaneCorporate':
						return 'Telco in a Box Corporate';
					case 'OctaneCorporateLegacy':
						return 'Telco in a Box Corporate Legacy';
					case 'OctaneResidential':
						return 'Telco in a Box Residential';
					default:
						return provider;
				}
			},
			formatBytes(bytes) { // Converts a number of bytes to a human readable size.
				// Determine the unit of the given bytes value based on the number of powers of 1024.
				const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
				const unit = Math.floor(Math.log(bytes) / Math.log(1024));
				
				// Convert the bytes value to the given unit, and return a string with the new value and the unit.
				const value = (bytes / Math.pow(1024, unit)).toFixed(0);
				return `${value} ${units[unit]}`;
			},
			formatTransferSpeed(bps) { // Converts a number of bits per second to a human readable transfer speed.
				// Determine the unit of the given bps value based on the number of powers of 1000.
				const units = ['bps', 'kbps', 'Mbps', 'Gbps', 'Tbps', 'Pbps', 'Ebps', 'Zbps', 'Ybps'];
				const unit = Math.floor(Math.log(bps) / Math.log(1000));
				
				// Convert the bps value to the given unit, and return a string with the new value and the unit.
				const value = (bps / Math.pow(1000, unit)).toFixed(0);
				return `${value} ${units[unit]}`;
			}
		}
	}
</script>

<style scoped lang="scss">
	#plan-list-wrapper {
		width:max-content;
		margin:0 auto;
	}
	
	#plan-list.hidden {
		visibility:hidden;
	}
	
	#filters {
		text-align:right;
		margin-bottom:20px;
		
		div {
			text-align:left;
			display:inline-block;
			margin-bottom:0;
		}
		
		label {
			display:block;
		}
		
		div, button {
			margin-left:20px;
		}
	}
	
	.no-plans {
		text-align:center;
	}
	
	.inactive-plan {
		color:var(--inactive-record-color);
	}
	
	.empty-field {
		color:var(--inactive-record-color);
	}
</style>