<template>
	<td :class="{'selection-cell': allowSelection, 'show-selection': showSelection, 'changing-selection': showSelectionIndicator, 'selection-mode': inSelectionMode}" @click="displaySelectionDropdown">
		<img src="@/assets/images/loading.gif" v-if="showSelectionIndicator">
		<div v-else>
			<slot></slot>
			<template v-if="allowSelection">
				<disclosure-button :disabled="true" :toggle-enabled="showSelection"></disclosure-button>
				<div v-if="isError" class="update-error">An error has occurred.</div>
				<div v-if="showSelection" class="selection-dropdown">
					<div v-for="(displayValue, value) in selectableValues" @click.stop="saveSelection(value)" :class="this.selectableValueClassPrefix ? (`${this.selectableValueClassPrefix}-${value}`) : ''">{{displayValue}}</div>
				</div>
			</template>
		</div>
	</td>
</template>

<script>
	import DisclosureButton from '@/components/DisclosureButton';
	
	export default {
		props: {
			recordIndex: Number,
			selectableValues: Object,
			selectingRecordIndex: Number,
			isSelecting: Boolean,
			allowSelection: {
				type: Boolean,
				default: true
			},
			isError: {
				type: Boolean,
				default: false
			},
			selectableValueClassPrefix: {
				type: String,
				default: null
			}
		},
		computed: {
			isSelectionCell() { // Used to determine if the this selection cell is the one that's being updated.
				return (this.recordIndex == this.selectingRecordIndex);
			},
			showSelection() { // Used to determine whether to display the selection dropdown menu.
				return (this.isSelectionCell && !this.isSelecting);
			},
			showSelectionIndicator() { // Used to determine whether to display the selection updating loading indicator.
				return (this.isSelectionCell && this.isSelecting);
			},
			inSelectionMode() { // Used to determine if the selection dropdown menu is displayed for any of the selection cells.
				return (this.selectingRecordIndex !== null);
			},
			canToggleSelectionDropdown() { // Used to determine whether the selection dropdown menu can be toggled. This is only possible if its currently displayed (to close it), or if no selection dropdown menu is currently displayed (to open it).
				return (!this.inSelectionMode || this.isSelectionCell);
			}
		},
		components: {
			DisclosureButton
		},
		methods: {
			displaySelectionDropdown() { // Sends an event to the parent component to toggle the visibility of the selection dropdown menu.
				if(this.allowSelection && this.canToggleSelectionDropdown) {
					this.$emit('show-selection', !this.showSelection);
				}
			},
			saveSelection(value) { // Sends an event to the parent component to save the value from the selection dropdown menu.
				this.$emit('save-selection', value);
			}
		}
	}
</script>

<style scoped lang="scss">
	.selection-cell {
		cursor:pointer;
		position:relative;
		
		&.show-selection, &.changing-selection {
			background-color:var(--highlight-color-dark);
		}
		
		&.changing-selection {
			text-align:center;
		}
		
		&.changing-selection, &.selection-mode:not(.show-selection), &.selection-mode:not(.show-selection) .disclosure-button {
			cursor:default;
		}
	}
	
	.disclosure-button {
		font-size:1rem;
		float:right;
		margin-left:10px;
		margin-right:-10px;
		cursor:pointer;
		color:var(--main-text-color);
	}
	
	img {
		width:30px;
	}
	
	.update-error {
		color:#FFFFFF;
		background-color:#FF0000;
		padding:5px;
		margin:10px -20px -20px -20px;
		border-top:1px solid var(--standard-border-color);
	}
	
	.selection-dropdown {
		background-color:var(--highlight-color-dark);
		border:2px solid var(--standard-border-color);
		text-align:center;
		position:absolute;
		left:10px;
		right:10px;
		top:80%;
		z-index:1;
		
		div {
			padding:10px;
			
			&:not(:last-child) {
				border-bottom:1px solid  var(--standard-border-color);
			}
			
			&:hover {
				background-color:var(--highlight-color-light);
			}
		}
	}
</style>