<template>
	<modal-view :title="creatingPlan ? 'Create Plan' : 'Edit Plan'" @close="$emit('close')">
		<div v-if="dataError" class="critical-error">An error has occurred.</div>
		<div v-else-if="savingPlan || loadingPlan" class="loading-indicator">
			<img src="@/assets/images/loading.gif">
		</div>
		<div v-else-if="planSaved" class="success-message">Plan {{creatingPlan ? 'Created' : 'Updated'}} Successfully</div>
		<div v-else>
			<form class="data-form" @submit.prevent="savePlan">
				<div>
					<label for="plan-name">Plan Name</label>
					<span class="required">*</span>
					<input type="text" id="plan-name" v-model="planName" @input="errorMessage = null" maxlength="255" />
				</div>
				<div>
					<label for="customer-type">Customer Type</label>
					<span class="required">*</span>
					<select id="customer-type" v-model="customerType" :disabled="!creatingPlan" @change="errorMessage = null">
						<option value="" disabled>Select...</option>
						<option value="corporate">Corporate</option>
						<option value="residential">Residential</option>
					</select>
				</div>
				<div>
					<label for="service-type">Service Type</label>
					<span class="required">*</span>
					<select id="service-type" v-model="serviceType" :disabled="!creatingPlan" @change="errorMessage = null">
						<option value="" disabled>Select...</option>
						<option value="NBN">NBN</option>
						<option value="Mobile">Mobile</option>
						<option value="CloudPBX">Cloud PBX</option>
						<option value="InboundVoice">Inbound Voice</option>
					</select>
				</div>
				<div v-show="showDataAllowance" class="number-input">
					<label for="data-allowance">Data Allowance (GB)</label>
					<span class="required">*</span>
					<input type="text" id="data-allowance" v-model="dataAllowance" @input="errorMessage = null" />
				</div>
				<div :class="{'number-input': showDataAllowance}">
					<label for="contract-months">Contract Months</label>
					<span class="required">*</span>
					<input type="text" id="contract-months" v-model="contractMonths" @input="errorMessage = null" />
				</div>
				<div class="number-input">
					<label for="monthly-cost">Monthly Cost</label>
					<span class="required">*</span>
					<input type="text" id="monthly-cost" v-model="monthlyCost" @input="errorMessage = null" />
				</div>
				<div class="number-input">
					<label for="setup-cost">Setup Cost</label>
					<span class="required">*</span>
					<input type="text" id="setup-cost" v-model="setupCost" @input="errorMessage = null" />
				</div>
				<div v-show="serviceType == 'NBN'" class="number-input">
					<label for="download-speed">Download Speed (Mbps)</label>
					<span class="required">*</span>
					<input type="text" id="download-speed" v-model="downloadSpeed" @input="errorMessage = null" />
				</div>
				<div v-show="serviceType == 'NBN'" class="number-input">
					<label for="upload-speed">Upload Speed (Mbps)</label>
					<span class="required">*</span>
					<input type="text" id="upload-speed" v-model="uploadSpeed" @input="errorMessage = null" />
				</div>
				<div v-if="serviceType == 'NBN'" class="checkboxes">
					<label class="checkboxes-label">Technology Types</label>
					<label><input type="checkbox" value="FTTB" v-model="technologyTypes" :disabled="initialTechnologyTypes.includes('FTTB')" @change="errorMessage = null" /> FTTB</label>
					<label><input type="checkbox" value="FTTC" v-model="technologyTypes" :disabled="initialTechnologyTypes.includes('FTTC')" @change="errorMessage = null" /> FTTC</label>
					<label><input type="checkbox" value="FTTN" v-model="technologyTypes" :disabled="initialTechnologyTypes.includes('FTTN')" @change="errorMessage = null" /> FTTN</label>
					<label><input type="checkbox" value="FTTP" v-model="technologyTypes" :disabled="initialTechnologyTypes.includes('FTTP')" @change="errorMessage = null" /> FTTP</label>
					<label><input type="checkbox" value="FW" v-model="technologyTypes" :disabled="initialTechnologyTypes.includes('FW')" @change="errorMessage = null" /> FW</label>
					<label><input type="checkbox" value="HFC" v-model="technologyTypes" :disabled="initialTechnologyTypes.includes('HFC')" @change="errorMessage = null" /> HFC</label>
				</div>
				<div>
					<label for="provider">Provider</label>
					<span class="required">*</span>
					<select id="service-type" v-model="provider" :disabled="!creatingPlan" @change="errorMessage = null">
						<option value="" disabled>Select...</option>
						<option value="OctaneCorporate">Telco in a Box Corporate</option>
						<option value="OctaneCorporateLegacy">Telco in a Box Corporate Legacy</option>
						<option value="OctaneResidential">Telco in a Box Residential</option>
					</select>
				</div>
				<template v-if="showProviderPlans">
					<div v-if="loadingProviderPlans" class="loading-indicator">
						<img src="@/assets/images/loading.gif">
					</div>
					<div v-else>
						<label for="provider-plan-id">Provider Plan</label>
						<span class="required">*</span>
						<v-select v-if="creatingPlan" id="provider-plan-id" v-model="providerPlanId" :options="formatProviderPlanListForDropdown()" :reduce="plan => plan.id" :selectable="plan => (plan.id !== null)" :clearable="false">
							<template v-slot:no-options>No matching provider plans found.</template>
						</v-select>
						<select v-else id="provider-plan-id" v-model="providerPlanId" disabled>
							<option v-for="plan in providerPlans" :value="plan.plan_id">{{plan.plan_name}}</option>
						</select>
					</div>
				</template>
				<template v-if="showPlanGroups">
					<div v-if="loadingPlanGroups" v-show="!loadingProviderPlans" class="loading-indicator">
						<img src="@/assets/images/loading.gif">
					</div>
					<div v-else>
						<label for="plan-group-id">Plan Group</label>
						<span class="required">*</span>
						<v-select id="plan-group-id" v-model="planGroupId" :options="formatPlanGroupListForDropdown()" :reduce="planGroup => planGroup.id" :selectable="planGroup => (planGroup.id !== null)" :clearable="false">
							<template v-slot:no-options>No matching plan groups found.</template>
						</v-select>
						<div v-if="!creatingPlan" v-show="planGroupChanged" class="plan-group-change-warning">Note: Changing the plan group that this plan is assigned to will move any existing services using this plan to the new plan group.</div>
					</div>
				</template>
				<div class="button-wrapper">
					<button type="submit">{{creatingPlan ? 'Create' : 'Save'}} Plan</button>
				</div>
			</form>
			<div class="critical-error">{{errorMessage}}</div>
		</div>
	</modal-view>
</template>

<script>
	import ModalView from '@/components/ModalView';
	import vSelect from 'vue-select';
	
	export default {
		props: {
			planId: String
		},
		data() {
			return {
				plan: null,
				planName: '',
				contractMonths: '',
				monthlyCost: '',
				setupCost: '',
				dataAllowance: '',
				downloadSpeed: '',
				uploadSpeed: '',
				technologyTypes: [],
				initialTechnologyTypes: [],
				customerType: '',
				serviceType: '',
				provider: '',
				planGroupId: null,
				planGroups: null,
				providerPlanId: null,
				providerPlans: null,
				savingPlan: false,
				planSaved: false,
				errorMessage: null,
				dataError: false,
				initialised: false
			}
		},
		computed: {
			creatingPlan() { // Used to determine whether a new service plan is being created, or an existing service plan is being updated.
				return (this.planId === null);
			},
			loadingPlan() { // Used to determine whether the updated service plan details are being loaded from the API.
				return (!this.creatingPlan && this.plan === null);
			},
			showDataAllowance() { // Used to determine whether to display the Data Allowance field. This is only displayed for Mobile and NBN plans.
				return ['Mobile', 'NBN'].includes(this.serviceType);
			},
			showPlanGroups() { // Used to determine whether to display the dropdown menu for selecting a plan group. This is only displayed if both a customer type and a service type have been selected.
				return (this.customerType != '' && this.serviceType != '');
			},
			loadingPlanGroups() { // Used to determine whether the list of plan groups are being loaded from the API.
				return (this.planGroups === null);
			},
			planGroupChanged() { // Used to determine whether the selected plan group has changed from the initial value.
				return (this.planGroupId != this.plan.plan_group.group_id);
			},
			showProviderPlans() { // Used to determine whether to display the dropdown menu for selecting a provider plan. This is only displayed if both a provider and a service type have been selected.
				return (this.provider != '' && this.serviceType != '');
			},
			loadingProviderPlans() { // Used to determine whether the list of provider plans are being loaded from the API.
				return (this.showProviderPlans && this.providerPlans === null);
			},
			bytesPerGigabyte() { // Returns the number of bytes in one gigabyte. Used for converting the data allowance value between bytes and gigabytes.
				return 1073741824;
			},
			bitsPerMegabit() { // Returns the number of bits in one megabit. Used for converting transfer speed values between bits and megabits.
				return 1000000;
			}
		},
		components: {
			ModalView, vSelect
		},
		async created() { // When the modal is loaded, check if we are editing an existing service plan, and if so, populate the form with the existing service plan details.
			if(!this.creatingPlan) {
				await this.getPlanDetails();
			}
		},
		mounted() { // When the modal is first displayed, check if we are creating a new service plan, and if so, set the form as initialised (since we don't need to load any plan details immediately).
			if(this.creatingPlan) {
				this.initialised = true;
			}
		},
		methods: {
			async getPlanDetails() { // Performs the API request to get the plan details for the given service plan.
				try {
					const response = await this.HTTP.get('plans/' + this.planId);
					this.plan = response.data.data;
					
					this.planName = this.plan.plan_name;
					this.contractMonths = this.plan.contract_months;
					this.monthlyCost = this.plan.monthly_cost;
					this.setupCost = this.plan.setup_cost;
					this.dataAllowance = (this.plan.plan_options.data_allowance !== null) ? this.plan.plan_options.data_allowance / this.bytesPerGigabyte : 0; // The data allowance is provided by the API in bytes, but needs to be displayed in gigabytes. If the value is NULL, it means there is no data cap, which is displayed as zero in the interface.
					this.downloadSpeed = this.plan.plan_options.download_speed / this.bitsPerMegabit; // The download speed is provided by the API in bits, but needs to be displayed in megabits.
					this.uploadSpeed = this.plan.plan_options.upload_speed / this.bitsPerMegabit; // The upload speed is provided by the API in bits, but needs to be displayed in megabits.
					this.technologyTypes = this.plan.plan_options.technology_types;
					this.initialTechnologyTypes = this.technologyTypes;
					this.customerType = this.plan.customer_type;
					this.serviceType = this.plan.service_type;
					this.provider = this.plan.provider;
					this.initialised = true;
					
					this.getPlanGroups().then(response => this.planGroupId = this.plan.plan_group.group_id);
					this.getProviderPlans().then(response => this.providerPlanId = this.plan.provider_plan_id)
				} catch(error) { // If there was an error obtaining the service plan details, display the generic error message.
					this.dataError = true;
				}
			},
			async getPlanGroups() { // Performs the API request to get the list of plan groups for the selected customer type and service type.
				// Clear the list of plan groups currently displayed to display the loading indicator, and clear the currently selected plan group, since it may no longer be available.
				this.planGroups = null;
				this.planGroupId = null;
				
				if(this.showPlanGroups) { // Only continue if the list of plan groups actually needs to be displayed.
					try {
						const response = await this.HTTP.get(`plan-groups?customer-type=${this.customerType}&service-type=${this.serviceType}&limit=1000`);
						this.planGroups = response.data.data;
					} catch(error) { // If there was an error obtaining the list of plan groups, display the generic error message.
						this.dataError = true;
					}
				}
			},
			async getProviderPlans() { // Performs the API request to get the list of provider plans for the selected provider.
				// Clear the list of plans currently displayed to display the loading indicator, and clear the currently selected plan, since it may no longer be available.
				this.providerPlans = null;
				this.providerPlanId = null;
				
				if(this.showProviderPlans) { // Only continue if the list of provider plans actually needs to be displayed.
					try {
						const response = await this.HTTP.get(`plans/provider-plans?provider=${this.provider}&service-type=${this.serviceType}`);
						this.providerPlans = response.data.data;
					} catch(error) { // If there was an error obtaining the list of provider plans, display the generic error message.
						this.dataError = true;
					}
				}
			},
			async savePlan() { // Performs the API request to create or update the given service plan.
				if(this.validateForm()) {
					try {
						// Replace the edit service plan form with a loading indicator.
						this.savingPlan = true;
						
						// Set the data to create or update the plan.
						const data = {plan_name: this.planName, group_id: this.planGroupId, contract_months: this.contractMonths, monthly_cost: this.monthlyCost, setup_cost: this.setupCost, plan_options: {}};
						if(this.showDataAllowance) { // For a service type that has a data allowance, set the data allowance in the update data. The data allowance is provided by the user in gigabytes, but the API requires it to be in bytes. If the value provided by the user is zero, it means there is no data cap, which is represented as NULL in the API request.
							data.plan_options.data_allowance = (this.dataAllowance > 0) ? this.dataAllowance * this.bytesPerGigabyte : null;
						}
						if(this.serviceType == 'NBN') { // For NBN services, set the upload and download speed in the update data. The speeds are provided by the user in Mbps, but the API requires them to be in bps.
							data.plan_options.download_speed = this.downloadSpeed * this.bitsPerMegabit;
							data.plan_options.upload_speed = this.uploadSpeed * this.bitsPerMegabit;
							data.plan_options.technology_types = this.technologyTypes;
						}
						if(this.creatingPlan) { // The customer type, service type, provider, and provider plan are only allowed if a new plan is being created, since these fields can't be modified once a plan is created.
							data.customer_type = this.customerType;
							data.service_type = this.serviceType;
							data.provider = this.provider;
							data.provider_plan_id = this.providerPlanId;
						}
						
						// Perform the API request to create or update the given service plan.
						(this.creatingPlan) ? await this.createPlan(data) : await this.updatePlan(data);
						
						// If the service plan was updated successfully, display the success message and instruct the parent component to reload the service plan list.
						this.planSaved = true;
						this.$emit('completed');
					} catch(error) { // If there was an error saving the service plan, display an error message below the edit service plan form.
						this.errorMessage = 'An error has occurred.';
					} finally { // Regardless of whether the API request was successful, hide the loading indicator and re-display the form.
						this.savingPlan = false;
					}
				}
			},
			async createPlan(data) { // Performs the API request to create a service plan with the given data.
				await this.HTTP.post('plans', data);
			},
			async updatePlan(data) { // Performs the API request to update the given service plan with the given data.
				await this.HTTP.put('plans/' + this.plan.id, data);
			},
			validateForm() { // Validates the data provided in the form.
				// Set the validation rules for each field in the edit form.
				const validationRules = {
					'plan name': {value: this.planName},
					'customer type': {value: this.customerType},
					'service type': {value: this.serviceType},
					'data allowance': {value: this.dataAllowance, format: 'integer'},
					'contract months': {value: this.contractMonths, format: 'integer'},
					'monthly cost': {value: this.monthlyCost, format: 'decimal'},
					'setup cost': {value: this.setupCost, format: 'decimal'},
					'download speed': {value: this.downloadSpeed, format: 'positive'},
					'upload speed': {value: this.uploadSpeed, format: 'positive'},
					'technology type': {value: this.technologyTypes},
					'provider': {value: this.provider},
					'provider plan': {value: this.providerPlanId},
					'plan group': {value: this.planGroupId}
				};
				
				// Remove the fields that don't need to be validated, based on the service type.
				if(this.serviceType != 'NBN') {
					delete validationRules['download speed'];
					delete validationRules['upload speed'];
					delete validationRules['technology type'];
				}
				if(!this.showDataAllowance) {
					delete validationRules['data allowance'];
				}
				
				// Validate that all the fields in the edit form have been filled in correctly.
				for(const field in validationRules) {
					// All of the fields in the form are required, so start by checking whether the given field was filled in.
					const fieldDetails = validationRules[field];
					if(fieldDetails.value === '' || fieldDetails.value === null) {
						this.errorMessage = `The ${field} is required.`;
						return false;
					}
					
					// For the technology type field, ensure that at least one value is provided.
					if(field == 'technology type' && fieldDetails.value.length == 0) {
						this.errorMessage = `At least one ${field} is required.`;
						return false;
					}
					
					// If the given field has a numeric field format, validate whether the field value matches the given format.
					if(fieldDetails.format && !this.validateNumericField(field, fieldDetails.format, fieldDetails.value)) {
						return false;
					}
				}
				
				return true;
			},
			validateNumericField(field, format, value) { // Validates that the given value is numeric.
				if(/^-/.test(value) || (format == 'positive' && value == 0)) { // All of the numeric fields must contain only positive numbers. The 'positive' format also disallows zero.
					this.errorMessage = `The ${field} must be a positive number.`;
					return false;
				}
				
				switch(format) {
					case 'integer': // For the 'integer' format, the value must only consist of numbers.
					case 'positive': // The 'positive' format is the same as the 'integer' format, except 0 isn't allowed.
						if(!/^\d+$/.test(value)) {
							this.errorMessage = `The ${field} must be an integer.`;
							return false;
						}
						break;
					case 'decimal': // For the 'decimal' format, the value must only consist of numbers, with an optional decimal component of two decimal places.
						if(!/^\d+(\.\d+)?$/.test(value)) {
							this.errorMessage = `The ${field} must be numeric.`;
							return false;
						}
						if(!/^\d+(\.\d{2})?$/.test(value)) {
							this.errorMessage = `The ${field} must have exactly two decimal places.`;
							return false;
						}
						break;
				}
				
				return true;
			},
			formatPlanGroupListForDropdown() { // Returns the list of plan groups formatted for use in the plan group list dropdown menu.
				const planGroupList = this.planGroups.map(function(planGroup){
					return {id: planGroup.id, label: planGroup.group_name};
				});
				planGroupList.unshift({id: null, label: 'Select...'});
				return planGroupList;
			},
			formatProviderPlanListForDropdown() { // Returns the list of provider plans formatted for use in the provider plan list dropdown menu.
				const planList = this.providerPlans.map(function(plan){
					return {id: plan.plan_id, label: plan.plan_name};
				});
				planList.unshift({id: null, label: 'Select...'});
				return planList;
			}
		},
		watch: {
			async provider() { // When the selected provider changes, load the correct list of provider plans.
				if(this.initialised) { // Don't do this when the provider is set to the initial value during the initialisation.
					await this.getProviderPlans();
				}
			},
			async serviceType() { // When the selected service type changes, load the correct list of plan groups and provider plans.
				if(this.initialised) { // Don't do this when the service type is set to the initial value during the initialisation.
					await Promise.allSettled([this.getPlanGroups(), this.getProviderPlans()]);
				}
			},
			async customerType() { // When the selected customer type changes, load the correct list of plan groups.
				if(this.initialised) { // Don't do this when the customer type is set to the initial value during the initialisation.
					await this.getPlanGroups();
				}
			},
			planGroupId() { // When a plan group is selected, we need to clear any error messages, just like we do for any other field.
				this.errorMessage = null;
			},
			providerPlanId() { // When a provider plan is selected, we need to clear any error messages, just like we do for any other field.
				this.errorMessage = null;
			}
		}
	}
</script>

<style scoped lang="scss">
	.data-form {
		width:50%;
		
		input, select {
			width:100%;
		}
		
		div.number-input {
			width:50%;
			float:left;
			
			&:nth-child(even) {
				padding-right:10px;
			}
			
			&:nth-child(odd) {
				padding-left:10px;
			}
		}
		
		.checkboxes-label {
			margin-bottom:5px;
		}
		
		.checkboxes label:not(.checkboxes-label) {
			display:inline-block;
			min-width:80px;
		}
	}
	
	.plan-group-change-warning {
		color:#FF0000;
		font-weight:bold;
		margin-top:5px;
	}
</style>